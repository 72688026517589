<template>
  <div class="viewContainer">
    <v-container>
      <v-row>
        <v-col
          align-self="center"
          xs="12"
          offset-xs="0"
          sm="10"
          offset-sm="1"
          md="8"
          offset-md="2"
          lg="6"
          offset-lg="3"
          xl="4"
          offset-xl="4"
        >
          <v-container class="pa-0">
            <v-row dense>
              <v-col cols="3">
                <v-btn large rounded block :text="(period !== 'today')" @click="period = 'today'" color="primary" class="no-caps mb-3">{{ $t('today') }}</v-btn>
              </v-col>
              <v-col cols="3">
                <v-btn large rounded block :text="(period !== 'week')" @click="period = 'week'" color="primary" class="no-caps mb-3">{{ $t('week') }}</v-btn>
              </v-col>
              <v-col cols="3">
                <v-btn large rounded block :text="(period !== 'month')" @click="period = 'month'" color="primary" class="no-caps mb-3">{{ $t('month') }}</v-btn>
              </v-col>
              <v-col cols="3">
                <v-btn large rounded block :text="(period !== 'all_time')" @click="period = 'all_time'" color="primary" class="no-caps mb-3">{{ $t('all_time') }}</v-btn>
              </v-col>
            </v-row>
          </v-container>

          <v-container class="pa-0">
            <v-row>
              <v-col cols="6">
                <v-card
                  outlined
                >
                  <v-list-item three-line>
                    <v-list-item-content>
                      <v-list-item-title class="mb-1" v-if="loading || analytics == null"><v-progress-circular indeterminate size="32" width="2"></v-progress-circular></v-list-item-title>
                      <v-list-item-title class="headline mb-1" v-if="!loading && analytics != null" v-html="formatNumber(analytics.pageViews)"/>

                      <v-list-item-subtitle>{{ $t('page_views') }}</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-icon size="48" class="ma-5">mdi-eye</v-icon>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card
                  outlined
                >
                  <v-list-item three-line>
                    <v-list-item-content>
                      <v-list-item-title class="mb-1" v-if="loading || analytics == null"><v-progress-circular indeterminate size="32" width="2"></v-progress-circular></v-list-item-title>
                      <v-list-item-title class="headline mb-1" v-if="!loading && analytics != null" v-html="formatNumber(analytics.pageClicks)"/>
                      <v-list-item-subtitle>{{ $t('link_clicks') }}</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-icon size="48" class="ma-5">mdi-gesture-tap</v-icon>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="period !== 'all_time'">
              <v-col cols="12">
                <v-card
                  outlined
                >
                  <v-responsive :aspect-ratio="2.9" class="fill-height text-center align-center">
                    <v-progress-circular class=" align-self-center" v-if="loading || analytics == null" indeterminate size="32" width="2"></v-progress-circular>
                    <v-sparkline
                      v-if="!loading && analytics != null"
                      :labels="analytics.viewsLabels"
                      :value="analytics.viewsValues"
                      type="trend"
                      smooth
                      stroke-linecap="round"
                      color="grey"
                      line-width="2"
                      padding="16"
                      height="100%"
                    ></v-sparkline>
                  </v-responsive>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  data: () => ({
    period: 'week',
    locale: 'en',
    loading: true,
    analytics: null
  }),
  created () {
    // Set locale
    let locale = Intl.DateTimeFormat().resolvedOptions().locale || 'en'
    locale = (this.$auth.check()) ? this.$auth.user().locale : locale
    this.locale = locale
    this.moment.locale(this.locale.substr(0,2))
    this.loadStats()
  },
  watch: {
    period: function (val) {
      this.loading = true
      this.loadStats()
    }
  },
  methods: {
    formatNumber (number) {
      return new Intl.NumberFormat(this.locale.replace('_', '-')).format(number)
    },
    loadStats (priod) {
      this.axios
        .get('/user/analytics', { params: {
          locale: this.$i18n.locale,
          period: this.period
        }})
        .then(response => {
          this.analytics = response.data
          this.loading = false
        })
    },
  }
}
</script>